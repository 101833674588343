<template>
  <div class="shop">
    <!-- 当前店铺 -->
    <div class="current-shop">
      当前店铺：
      <el-select
        v-model="queryInfo.merchantsId"
        placeholder="请选择店铺"
        clearable=""
        filterable
        @clear="getShopProductList"
        @change="getShopProductList"
      >
        <el-option
          v-for="item in shopList"
          :key="item.id"
          :label="item.merchantsInfo.shopName"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button
            type="primary"
            @click="showAddShopProductDialog"
          >+ 新增店铺商品</el-button>
        </el-col>
      </el-row>
      <!-- 第二行，筛选 -->
      <div class="secondrow">
        <!-- 根据商品名查询 -->

        <div class="input">
          商品：
          <el-input
            placeholder="请输入商品名称"
            v-model="queryInfo.name"
            clearable
            @clear="getShopProductList"
            @change="searchMerchant"
          >
            <el-button
              slot="append"
              @click="searchMerchant"
            >搜索</el-button>
          </el-input>
        </div>

        <!-- 根据商品类别查询 -->

        <div class="input">
          所属分类：
          <el-select
            v-model="queryInfo.categoryId"
            clearable
            @clear="getShopProductList"
            placeholder="请选择商品分类查询"
            @change="searchMerchant"
            class="select1"
          >
            <el-option
              v-for="item in goodsCateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <!-- 计价方式 -->

        <div class="input">
          计价方式：
          <el-select
            v-model="queryInfo.feeType"
            clearable
            @clear="getShopProductList"
            placeholder="请选择计价方式"
            @change="searchMerchant"
            class="select2"
          >
            <el-option
              v-for="item in feeTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <!-- 状态 -->

        <div class="input">
          商品状态：
          <el-select
            v-model="queryInfo.status"
            clearable
            @clear="getShopProductList"
            placeholder="请选择状态"
            @change="searchMerchant"
            class="select2"
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 内容主体 -->
      <el-table
        :data="shopProductList"
        stripe
        style="width: 100%"
        border
      >
        <!-- 第一列id -->
        <el-table-column
          label="商品id"
          width="100"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 店铺名称 -->
        <el-table-column
          label="店铺名称"
          aligin="center"
          prop="shopName"
        >
        </el-table-column>
        <!-- icon -->
        <el-table-column
          label="商品图片"
          width="110"
          aligin="center"
        >
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              title=""
              placement="bottom"
              v-if="scope.row.icon"
            >
              <img
                :src="scope.row.icon"
                width="200"
                height="200"
              />
              <img
                slot="reference"
                :src="scope.row.icon"
                style="height: 80px;width: 80px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <!-- 商品名称 -->
        <el-table-column
          label="商品名称"
          aligin="center"
          prop="name"
        >
        </el-table-column>
        <!-- 所属分类 -->
        <el-table-column
          label="所属分类"
          aligin="center"
          prop="productCategoryVo.name"
        >
        </el-table-column>
        <!-- 商品类型，0线下 1线上 -->
        <!-- <el-table-column label="商品类型" width="90" aligin="center">
          <template slot-scope="scope">
            <div v-if="scope.row.online === 0">线下</div>
            <div v-else>线上</div>
          </template>
        </el-table-column> -->
        <!-- 计价类型 0-称重 1-计件 -->
        <!-- <el-table-column label="计价类型" width="90" aligin="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.feeType === 0">称重</el-tag>
            <el-tag v-if="scope.row.feeType === 1">计件</el-tag>
          </template>
        </el-table-column> -->
        <!-- 售价单位为分 -->
        <el-table-column
          label="售价"
          aligin="center"
          prop="salePrice"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.feeType === 0">
              ￥{{ scope.row.salePrice / 100 }} /kg
            </div>
            <div v-else>￥{{ scope.row.salePrice / 100 }} /份</div>
          </template>
        </el-table-column>
        <!-- 售价单位为分 -->
        <!-- <el-table-column
          label="商品原价"
          width="70"
          aligin="center"
          prop="originPrice"
        >
        </el-table-column> -->
        <!-- 商品编码 -->
        <el-table-column
          label="商品编码"
          aligin="center"
          prop="productCode"
        >
        </el-table-column>
        <!-- 商品描述 -->
        <el-table-column
          label="商品描述"
          aligin="center"
          prop="productDescription"
        >
        </el-table-column>
        <!-- 状态 -->
        <el-table-column
          label="状态"
          aligin="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status === 1">
              未上架
            </div>
            <div v-else>已上架</div>
          </template>
        </el-table-column>
        <!-- 排序 -->
        <!-- <el-table-column
          label="排序"
          width="60"
          aligin="center"
          prop="sortIndex"
        >
        </el-table-column> -->
        <!-- 操作 -->
        <el-table-column
          label="操作"
          width="250"
          aligin="center"
        >
          <!-- 上架按钮 -->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="success"
              @click="up(scope.row)"
              v-if="scope.row.status === 1"
            >上架</el-button>
            <!-- 下架 -->
            <el-button
              size="mini"
              type="warning"
              @click="down(scope.row)"
              v-else-if="scope.row.status === 0"
            >下架</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="showEditShopProductDialog(scope.row)"
            >修改</el-button>
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeShopProductById(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加商户对话框 -->
    <el-dialog
      @close="clearAddInfo"
      :title="title"
      :visible.sync="addShopProductDialogVisible"
      width="800px"
    >
      <el-form
        ref="addShopProductFormRef"
        :rules="addShopProductRules"
        :model="productForm"
        label-width="100px"
      >
        <p style="font-style:italic;margin-bottom:20px;">
          为了方便您的使用，请先选择标准商品，如果标准商品库中没有您所需要的商品，请自行添加
        </p>
        <div class="form">
          <el-form-item
            label="店铺名称"
            prop="merchantsId"
          >
            <el-select
              v-model="productForm.merchantsId"
              placeholder="请选择店铺"
              autocomplete="off"
              @change="selectMerchant"
              class="select"
              :disabled="isShow"
            >
              <el-option
                v-for="item in shopList"
                :key="item.id"
                :label="item.merchantsInfo.shopName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="标准商品"
            prop="productStandardId"
          >
            <el-select
              v-model="productForm.productStandardId"
              filterable
              placeholder="请选择标准商品"
              @change="selectStandardProduct"
            >
              <el-option
                v-for="item in productStandardList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form">
          <el-form-item
            label="商品名称"
            prop="name"
          >
            <el-input
              v-model="productForm.name"
              placeholder="请输入商品名称"
              class="select"
              @input="selectMerchant"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="商品图标"
            prop="icon"
          >
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType1"
              :before-upload="beforeUpload"
              :on-success="handlesuccess1"
              :show-file-list="false"
            >
              <img
                v-if="productForm.icon"
                :src="productForm.icon"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
        </div>
        <div class="form">
          <el-form-item
            label="商品分类"
            prop="categoryId"
          >
            <el-select
              v-model="productForm.categoryId"
              placeholder="请选择商品分类"
              class="select"
              @change="selectMerchant"
            >
              <el-option
                v-for="item in goodsCateList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="商品类型"
            prop="online"
          >
            <el-select
              v-model="productForm.online"
              placeholder="请选择商品类型"
              class="select"
              @change="selectMerchant"
            >
              <el-option
                v-for="item in onlineList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form">
          <el-form-item
            label="计价类型"
            prop="feeType"
          >
            <el-select
              v-model="productForm.feeType"
              placeholder="请选择计价类型"
              class="select"
              @change="selectMerchant"
            >
              <el-option
                v-for="item in feeTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="商品编码"
            prop="productDescription"
          >
            <el-input
              v-model="productForm.productCode"
              placeholder="请输入商品编码"
              class="select"
              @input="selectMerchant"
            >
            </el-input>
          </el-form-item>
        </div>
        <div class="form">
          <el-form-item
            label="商品售价"
            prop="salePrice"
          >
            <el-input
              v-model="newSalePrice"
              placeholder="请输入商品售价"
              class="select"
              @input="selectMerchant"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="商品原价"
            prop="originPrice"
          >
            <el-input
              v-model="newOriginPrice"
              placeholder="请输入商品原价"
              class="select"
              @input="selectMerchant"
            >
            </el-input>
          </el-form-item>
        </div>

        <div class="form">
          <el-form-item
            label="商品描述"
            prop="productDescription"
          >
            <el-input
              v-model="productForm.productDescription"
              placeholder="请输入商品描述"
              type="textarea"
              :rows="3"
              class="select3"
              @input="selectMerchant"
            >
            </el-input>
          </el-form-item>
        </div>
        <div class="form"></div>

        <el-form-item
          label="类别下排序"
          prop="categorySortIndex"
        >
          <el-input-number
            v-model="productForm.categorySortIndex"
            placeholder="请输入排序"
            :min="0"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item
          label="全部排序"
          prop="sortIndex"
        >
          <el-input-number
            v-model="productForm.sortIndex"
            placeholder="请输入排序"
            :min="0"
          >
          </el-input-number>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addShopProductDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 店铺id
      shopId: '',
      // 店铺数组
      shopList: [],
      // 店铺请求入参
      shopQueryInfo: {
        limit: 2000,
        pageNo: 1,
        marketId: ''
      },
      // 查询商家商品入参
      queryInfo: {
        limit: 15,
        pageNo: 1,
        categoryId: '', // 分类
        merchantsId: '',
        name: '', // 商品名称
        feeType: '', // 计价方式 0-称重 1-计件
        status: '',
        marketId: ''
      },
      // 商家商品列表
      shopProductList: [],
      // 总数
      total: 0,
      // 添加商品类别入参
      productForm: {
        marketId: '',
        merchantsId: '', // 商户id
        categoryId: '', // 商品分类ID
        categorySortIndex: '', // 商品在类别下排序
        feeType: '', // 计价类型 0-称重 1-计件
        icon: '', // 商品图标
        name: '',
        online: '', // 商品类型 0-线下 1-线上
        originPrice: '', // 原价单位为分
        productCode: '', // 商品编码或条形码
        productDescription: '', // 商品描述
        productStandardId: '', // 标准商品id
        salePrice: '', // 售价单位为分
        sortIndex: ''
      },
      // 换算成元的售价
      newSalePrice: '',
      // 原价
      newOriginPrice: '',
      // 新增对话框，默认false
      addShopProductDialogVisible: false,
      // 新增和修改公用一个对话框
      title: '',
      submitType: '',
      // 校验规则
      addGoodsCateRules: {
        productCategoryId: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ]
      },
      // 修改所需要的商家商品id
      shopProductId: '',
      // 标准商品表查询入参
      productStandardQueryInfo: {
        limit: 1000,
        pageNo: 1,
        name: ''
      },
      // 标准商品数组
      productStandardList: [],
      // 校验规则
      addShopProductRules: {
        merchantsId: [
          { required: true, message: '请选择店铺', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        categoryId: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ],
        feeType: [
          { required: true, message: '请选择计价类型', trigger: 'blur' }
        ]
      },
      // 选中标准商品，用来赋值给添加入参里
      productStandardForm: [],
      // 计价类型，自定义数组
      feeTypeList: [
        {
          id: 0,
          name: '称重'
        },
        {
          id: 1,
          name: '计件'
        }
      ],
      // 状态
      statusList: [
        {
          id: 1,
          name: '未上架'
        },
        {
          id: 0,
          name: '已上架'
        }
      ],
      // 商品类型，自定义数组
      onlineList: [
        {
          id: 0,
          name: '线下'
        },
        {
          id: 1,
          name: '线上'
        }
      ],
      // 上传学校logo的type
      bizType1: {
        bizType: 'productType'
      },
      // 查看商品分类的入参
      GoodsCateQueryInfo: {
        limit: 200,
        pageNo: 1
      },
      // 商品分类数组
      goodsCateList: [],
      // 是否禁用店铺选择
      isShow: true
    }
  },
  created () {
    // 获取商户列表
    this.getMerchantList()
    // 取上级路由传送过来的id
    this.queryInfo.merchantsId = this.$route.query.id
    // 调用列表接口
    this.getShopProductList()
    // 调用分类
    this.getGoodsCate()
  },
  methods: {
    searchMerchant () {
      this.queryInfo.pageNo = 1
      this.getShopProductList()
    },
    // 调用商户列表方法
    async getMerchantList () {
      // 赋值marketId
      this.shopQueryInfo.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      const { data: res } = await this.$http.get('merchants/list', {
        params: this.shopQueryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查看商户失败')
      }
      this.shopList = res.data.data
    },
    // 调用商家商品查询列表
    async getShopProductList () {
      // 赋值marketId
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get('product/productList', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查看商家商品失败')
      }
      this.shopProductList = res.data.data
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getShopProductList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getShopProductList()
    },
    // 请求标准商品表
    async getProductStandardList () {
      const { data: res } = await this.$http.get('productStandard/query', {
        params: this.productStandardQueryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查看标准商品失败')
      }
      this.productStandardList = res.data.data
      // 赋值其他标准商品传递给商家商品数据
    },
    // 打开添加商家商品对话框
    showAddShopProductDialog () {
      // 调用标准商品表
      this.getProductStandardList()
      // 请求商品分类
      this.getGoodsCate()
      // 商户id直接赋值
      this.productForm.merchantsId = this.queryInfo.merchantsId
      this.title = '添加商家商品'
      this.submitType = 'add'
      this.isShow = false
      this.addShopProductDialogVisible = true
    },
    // 打开修改商家商品对话框
    showEditShopProductDialog (row) {
      // 调用标准商品表
      this.getProductStandardList()
      // 请求商品分类
      this.getGoodsCate()
      this.productForm = row
      this.shopProductId = row.id
      this.title = '修改商家商品'
      this.submitType = 'edit'
      this.isShow = true
      // 店铺id需要换成整型
      this.productForm.merchantsId = parseInt(row.merchantsId)
      // 换算原价和售价
      this.newSalePrice = row.salePrice / 100
      this.newOriginPrice = row.originPrice / 100
      this.addShopProductDialogVisible = true
    },
    // 提交请求
    submit () {
      this.productForm.salePrice = this.newSalePrice * 100
      this.productForm.originPrice = this.newOriginPrice * 100
      this.$refs.addShopProductFormRef.validate(async valid => {
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          // 赋值marketId
          this.productForm.marketId = window.sessionStorage.getItem(
            'currentMarketId'
          )
          // 赋值售价
          // this.productForm.salePrice = this.newSalePrice * 100
          // this.productForm.originPrice = this.newOriginPrice * 100
          const { data: res } = await this.$http.post(
            'product',
            this.productForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加商家商品成功')
          this.getShopProductList()
          this.addShopProductDialogVisible = false
        } else {
          // 赋值售价
          // this.productForm.salePrice = this.newSalePrice * 100
          // this.productForm.originPrice = this.newOriginPrice * 100
          const { data: res } = await this.$http.put(
            `product/${this.shopProductId}`,
            this.productForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('修改商家商品成功')
          this.getShopProductList()
          this.addShopProductDialogVisible = false
        }
      })
    },
    // 关闭对话框
    clearAddInfo () {
      this.productForm = {}
      this.$refs.addShopProductFormRef.resetFields()
      this.getShopProductList()
      // 清除原价和售价
      this.newSalePrice = ''
      this.newOriginPrice = ''
    },
    // 删除操作
    async removeShopProductById (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该商家商品，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`product/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除商家商品失败')
      }
      this.$message.success('删除商家商品成功')
      this.getShopProductList()
    },
    // 选择标准商品，进行赋值
    selectStandardProduct () {
      // 遍历数组里的对象，找到选中那行商品id所在的对象
      this.productStandardForm = this.productStandardList[
        this.productStandardList.findIndex(
          i => i.id === this.productForm.productStandardId
        )
      ]
      // 将找到的对象进行赋值
      this.productForm.name = this.productStandardForm.name
      this.productForm.icon = this.productStandardForm.icon
      this.productForm.feeType = this.productStandardForm.feeType
      this.productForm.salePrice = this.productStandardForm.price
      this.productForm.categoryId = this.productStandardForm.categoryVo.id
    },
    beforeUpload (file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('上传图片只能是jpg、jpeg、bmp、png格式')
        return false
      }
      return true
    },
    // 监听图标上传成功的钩子
    handlesuccess1 (response, file, fileList) {
      this.$forceUpdate()
      this.productForm.icon = response.data
    },
    // 请求商品分类
    async getGoodsCate () {
      const { data: res } = await this.$http.get('productCategory/list', {
        params: this.GoodsCateQueryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('查询商品分类失败')
      }
      this.goodsCateList = res.data.data
      console.log(this.goodsCateList)
    },
    // 上架
    async up (row) {
      const { data: res } = await this.$http.put(
        `product/updateStatus?id=${row.id}&status=0`
      )
      if (res.code !== 0) {
        return this.$message.error('上架失败')
      }
      this.$message.success('上架成功')
      this.getShopProductList()
    },
    // 下架
    async down (row) {
      const { data: res } = await this.$http.put(
        `product/updateStatus?id=${row.id}&status=1`
      )
      if (res.code !== 0) {
        return this.$message.error('下架失败')
      }
      this.$message.success('下架成功')
      this.getShopProductList()
    },
    // 强制更新
    selectMerchant () {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.avatar {
  width: 70px;
  height: 70px;
}
.shop {
  position: relative;
}
.current-shop {
  position: absolute;
  top: -60px;
  left: 353px;
}
.el-row {
  margin-bottom: 20px;
}
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
  margin-right: -40px;
}
.input:nth-child(3) {
  margin-left: -30px;
}
.input:nth-child(4) {
  margin-left: -50px;
}
.select1 {
  width: 180px;
}
.select2 {
  width: 150px;
}
.el-input {
  width: 240px;
}
.secondrow {
  display: flex;
  margin-left: -20px;
}
.form {
  display: flex;
  // justify-content: space-between;
  justify-content: flex-start;
}
.el-form-item {
  margin-right: 60px;
}
.select {
  width: 220px;
}
.select3 {
  width: 600px;
}
.avatar-uploader-icon {
  font-size: 22px;
}
</style>
